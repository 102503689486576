
import { defineComponent } from "vue";
import { Parallax, Carousel, MasonryGallery } from "@/components";
import { newsCollection } from "@/plugins/firebase";
import { NewsItem } from "@/apiServices/interface";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import ApiController from "@/apiServices/ApiController";

export default defineComponent({
  name: "News",
  components: { Parallax, Carousel, MasonryGallery },
  data() {
    return {
      newslist: [] as NewsItem[],
      pendingRequest: false,
      pageLimit: 9,
      newsItemCounter: 9,
      lastestDoc: null as any,
      endOfList: false,
      timestampToDate
    };
  },
  methods: {
    handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;
      const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;

      if (bottomOfWindow) {
        this.getNewsItems();
      }
    },
    async getNewsItems() {
      if (this.pendingRequest || this.endOfList) {
        return;
      }
      this.pendingRequest = true;
      const newsItems = await ApiController.news.getNewsItemList(this.pageLimit, this.lastestDoc);

      newsItems?.docs.map(doc =>
        this.newslist.push(({ id: doc.id, ...doc?.data() } as unknown) as NewsItem)
      );

      if (!newsItems?.docs.length || newsItems?.docs.length < this.pageLimit) {
        this.endOfList = true;
        this.pendingRequest = false;
        return;
      }

      this.newsItemCounter += this.pageLimit;
      this.pendingRequest = false;
    }
  },

  watch: {
    newsItemCounter: async function() {
      if (this.newslist.length) {
        console.log("getting");
        const latest = this.newslist[this.newslist.length - 1].id;
        this.lastestDoc = await newsCollection.doc(latest).get();
      }
    }
  },
  created() {
    this.getNewsItems();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
});
