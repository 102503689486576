
import { defineComponent } from "vue";
import { Parallax, Carousel, AchievementCounter } from "@/components";
import { NewsItem } from "@/apiServices/interface";
import { storage } from "@/plugins/firebase";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import ApiController from "@/apiServices/ApiController";

export default defineComponent({
  name: "NewsDetail",
  components: { Parallax, Carousel, AchievementCounter },
  props: ["itemId"],
  data() {
    return {
      newsDetail: {} as NewsItem,
      newsImages: [] as string[],
      pendingRequest: false,
      timestampToDate
    };
  },
  methods: {
    async getNewsItem(newsItemId: string) {
      if (this.pendingRequest) {
        return;
      }
      try {
        this.pendingRequest = true;
        const newsItem = await ApiController.news.getNewsItem(newsItemId);

        if (newsItem) {
          this.newsDetail = ({ ...newsItem, id: newsItemId } as unknown) as NewsItem;
        }
      } catch (error) {
        console.warn(error);
      }
      this.pendingRequest = false;
    },
    async getImages(newsItemId: string) {
      const storageRef = storage.ref().child(`news/${newsItemId}`);
      this.newsImages = [];
      const itemList = await storageRef.listAll();
      itemList.items.forEach(async e => this.newsImages.push(await e.getDownloadURL()));
    }
  },

  created() {
    this.getNewsItem(this.itemId);
    this.getImages(this.itemId);
  },
  watch: {
    itemId(updatedId) {
      this.getNewsItem(updatedId);
      this.getImages(updatedId);
    }
  }
});
